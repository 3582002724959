var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-button',{staticClass:"mb-2 d-none",attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.checkAll}},[_vm._v(" Check All (For Testing) ")]),_c('div',{staticClass:"inspection-report"},[_c('video-player',{ref:"video"}),_c('validation-observer',{ref:"editForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('n-form-confirmation',{key:"editForm",ref:"confirmForm",attrs:{"ok-variant":"success","form":_vm.$refs.editForm,"disabled":invalid},on:{"submit":_vm.save}},[_c('b-table-simple',{staticClass:"table-information mb-0",attrs:{"responsive":"","borderless":"","striped":"","sticky-header":""}},[_vm._l((_vm.inspectionForm),function(main,i){return [_c('b-thead',[_c('b-tr',[_c('b-th',{attrs:{"width":"50"}},[_vm._v("#")]),_c('b-th',[_vm._v(_vm._s(_vm.trans(main, "label", _vm.$i18n.locale)))]),_vm._l((main.options),function(option,j){return _c('b-th',{key:("option-" + i + "-" + j),staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.trans(option, "label", _vm.$i18n.locale))+" ")])}),_c('b-th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("field.na"))+" ")])],2)],1),_c('b-tbody',_vm._l((main.points),function(point,j){return _c('b-tr',{key:("point-" + i + "-" + j),class:{
                  na: _vm.noAnswers.includes(point.value.answer),
                }},[_c('b-td',[_vm._v(" "+_vm._s(j + 1)+" ")]),_c('b-td',[_vm._v(" "+_vm._s(_vm.trans(point, "label", _vm.$i18n.locale))+" "),(
                      point.value.failOptions &&
                      point.value.failOptions.length
                    )?_c('div',{staticClass:"fail-options"},[(
                        point.value.failMediaId &&
                        point.value.failMedia &&
                        _vm.checkIsImage(point.value.failMedia.fileType)
                      )?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.viewImage(point.value.failMedia)}}},[_c('feather-icon',{attrs:{"icon":"ImageIcon"}})],1):_vm._e(),(
                        point.value.failMediaId &&
                        point.value.failMedia &&
                        !_vm.checkIsImage(point.value.failMedia.fileType)
                      )?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.viewVideo(point.value.failMedia)}}},[_c('feather-icon',{attrs:{"icon":"VideoIcon"}})],1):_vm._e(),_vm._l((point.value.failOptions),function(failOption,k){return _c('b-badge',{key:("fail-option-" + i + "-" + j + "-" + k),staticClass:"text-capitalize",attrs:{"variant":"light-danger","pill":""}},[_vm._v(" "+_vm._s(_vm.trans( _vm.getFailOptions(failOption, point.failOptions), "label", _vm.$i18n.locale ))+" ")])})],2):_vm._e()]),_c('b-td',{staticClass:"pass text-center"},[_c('b-form-checkbox',{attrs:{"name":("option-" + i + "-" + j),"value":1,"plain":"","size":"lg"},on:{"change":function($event){return _vm.onChangeOption(1, i, j)}},model:{value:(point.value.answer),callback:function ($$v) {_vm.$set(point.value, "answer", $$v)},expression:"point.value.answer"}})],1),_c('b-td',{staticClass:"fail text-center"},[_c('b-form-checkbox',{attrs:{"name":("option-" + i + "-" + j),"value":0,"plain":"","size":"lg"},on:{"change":function($event){return _vm.onChangeOption(0, i, j)}},model:{value:(point.value.answer),callback:function ($$v) {_vm.$set(point.value, "answer", $$v)},expression:"point.value.answer"}})],1),_c('b-td',{staticClass:"fail text-center"},[(!point.required)?_c('b-form-checkbox',{attrs:{"name":("option-" + i + "-" + j),"value":2,"plain":"","size":"lg"},on:{"change":function($event){return _vm.onChangeOption(2, i, j)}},model:{value:(point.value.answer),callback:function ($$v) {_vm.$set(point.value, "answer", $$v)},expression:"point.value.answer"}}):_vm._e()],1)],1)}),1)]})],2)],1)]}}])})],1),_c('fail-option-modal',{ref:"failOptionModal",on:{"save":_vm.saveFailOption}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
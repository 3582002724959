<template>
  <div>
    <validation-observer ref="editForm" #default="{ invalid }">
      <n-form-confirmation
        ref="confirmForm"
        key="editForm"
        @submit="save"
        ok-variant="success"
        :form="$refs.editForm"
        :disabled="invalid"
      >
        <div class="main-image-upload">
          <div
            class="mb-2 mr-2 item"
            v-for="(item, i) in preImages"
            :key="`pre-image-${i}`"
          >
            <input
              type="file"
              style="display: none"
              name="fields[assetsFieldHandle][]"
              class="w-px h-px opacity-0 overflow-hidden absolute"
              @change="onChange(i)"
              :ref="`fileInput${i}`"
              accept=".jpg,.jpeg,.png"
            />
            <b-card no-body class="mb-0">
              <div class="text-center cursor-pointer" @click="changeImage(i)">
                <div class="text-center">
                  {{ trans(item, "name", $i18n.locale) }}
                </div>
                <b-img fluid :src="item.media.fileUrl" v-if="item.media" />
                <b-img
                  v-else
                  fluid
                  :src="require(`@/assets/images/preimages/${item.preImage}`)"
                />
              </div>
              <b-button
                :disabled="!item.media"
                :variant="!item.media ? 'secondary' : 'danger'"
                size="sm"
                block
                pill
                class="mt-1"
                @click="removePreImage(i)"
              >
                {{ $t("button.delete") }}
              </b-button>
            </b-card>
          </div>
          <draggable
            v-model="items"
            draggable=".image-item"
            class="main-image-upload extra-image"
          >
            <div
              class="mb-2 mr-2 image-item item"
              v-for="(item, i) in items"
              :key="`main-image-${i}`"
            >
              <b-card no-body class="mb-0">
                <div class="text-center">
                  <b-img
                    fluid
                    :src="item.media.fileUrl"
                    v-if="item && item.media"
                  />
                </div>
                <b-button
                  variant="danger"
                  size="sm"
                  block
                  pill
                  class="mt-1"
                  @click="removeItem(i)"
                >
                  {{ $t("button.delete") }}
                </b-button>
              </b-card>
            </div>
            <div class="pb-2 pr-2">
              <div class="btn-add" @click="addNewItem">
                <b-spinner v-if="loading"></b-spinner>
                <feather-icon icon="PlusIcon" size="28" v-else />
              </div>
            </div>
          </draggable>
        </div>
      </n-form-confirmation>
    </validation-observer>

    <input
      type="file"
      style="display: none"
      name="fields[assetsFieldHandle][]"
      class="w-px h-px opacity-0 overflow-hidden absolute"
      @change="onChange"
      ref="fileInput"
      accept=".jpg,.jpeg,.png"
    />
  </div>
</template>

<script>
import { BRow, BCol, BButton, BCard, BImg, BSpinner } from "bootstrap-vue";
import NSingleImageUploader from "@/components/NSingleImageUploader";
import NFormConfirmation from "@/components/NFormConfirmation";
import draggable from "vuedraggable";
import Repository from "@/repositories/RepositoryFactory";
import NButtonLoading from "@/components/NButtonLoading.vue";
import PreImages from "./preImages";

const InspectionRepository = Repository.get("inspection");
const FileRepository = Repository.get("file");

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BImg,
    BSpinner,

    NSingleImageUploader,
    NFormConfirmation,
    draggable,
    NButtonLoading,
  },
  props: {
    value: {},
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      items: [],
      preImages: [],
    };
  },
  methods: {
    initData(items) {
      let preItems = [];
      let extraItems = [];
      items.forEach((element) => {
        if (element.key) {
          preItems.push(element);
        } else {
          extraItems.push(element);
        }
      });
      let preImages = [];
      PreImages.forEach((element) => {
        let index = preItems.findIndex((row) => {
          return row.key == element.key;
        });
        if (index != -1) {
          let pre = {
            ...element,
            media: preItems[index].media,
            mediaId: preItems[index].mediaId,
          };
          preImages.push(pre);
        } else {
          preImages.push(element);
        }
      });
      this.preImages = [...preImages];
      this.items = [...extraItems];
    },
    uploadImage(event, i = null) {
      this.loading = true;
      let formData = new FormData();
      formData.append("file", event);
      FileRepository.uploadStream(formData)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            if (i == null) {
              this.items = [
                ...this.items,
                {
                  mediaId: data.mediaId,
                  media: { ...data },
                },
              ];
            } else {
              this.preImages[i].mediaId = data.mediaId;
              this.preImages[i].media = { ...data };
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        })
        .then(() => {});
    },
    onChange(i = null) {
      if (i == null) {
        this.filelist = [...this.$refs.fileInput.files];
        var reader = new FileReader();
        reader.onload = (e) => {
          this.uploadImage(this.filelist[0]);
        };
        reader.readAsDataURL(this.filelist[0]);
      } else {
        this.filelist = [...this.$refs[`fileInput${i}`][0].files];
        var reader = new FileReader();
        reader.onload = (e) => {
          this.uploadImage(this.filelist[0], i);
        };
        reader.readAsDataURL(this.filelist[0], i);
      }
    },
    addNewItem() {
      if (this.loading) {
        return;
      }

      this.$refs.fileInput.value = null;
      this.$refs.fileInput.click();
    },
    removeItem(i) {
      this.items.splice(i, 1);
    },
    changeImage(i) {
      if (this.loading) {
        return;
      }
      this.$refs[`fileInput${i}`][0].value = null;
      this.$refs[`fileInput${i}`][0].click();
    },
    removePreImage(i) {
      this.preImages[i].media = null;
      this.preImages[i].mediaId = null;
    },
    submit() {
      if (!this.isChange()) {
        this.$emit("cancel");
        return;
      }

      this.$refs.editForm.validate().then((success) => {
        if (success) {
          this.$refs.confirmForm.confirm();
        }
      });
    },
    save() {
      this.loading = true;
      let data = [];
      this.preImages.forEach((element) => {
        if (element.mediaId) {
          data.push({
            key: element.key,
            mediaId: element.mediaId,
          });
        }
      });
      this.items.forEach((element) => {
        data.push({
          key: null,
          mediaId: element.mediaId,
        });
      });
      InspectionRepository.updateMainImage(this.$route.params.id, {
        mainImageRequests: data,
      })
        .then((response) => {
          this.$emit("success", this.items);
          this.items = [];
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    isChange() {
      let defaultValue = [];
      this.data.forEach((element) => {
        defaultValue.push(element.mediaId);
      });

      let updatedValue = [];
      this.preImages.forEach((element) => {
        if (element.mediaId) {
          updatedValue.push(element.mediaId);
        }
      });
      this.items.forEach((element) => {
        updatedValue.push(element.mediaId);
      });

      return JSON.stringify(defaultValue) != JSON.stringify(updatedValue);
    },
    cancel() {
      if (this.isChange()) {
        this.$bvModal
          .msgBoxConfirm(this.$t("alert.hasUnsavedChange"), {
            title: this.$t("alert.areYouSure"),
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: this.$t("button.discardChanged"),
            cancelTitle: this.$t("button.cancel"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.$emit("cancel");
            }
          });
      } else {
        this.$emit("cancel");
      }
    },
  },
  setup() {
    const resource = "vehicle";
    const route = "vehicle";

    return {
      resource,
      route,
    };
  },
};
</script>
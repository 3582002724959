export default [
  {
    labelEn: "Accident History",
    labelKm: "ផ្នែកគ្រោះថ្នាក់",
    options: [
      {
        value: 1,
        labelEn: "Pass",
        labelKm: "ល្អ",
      },
      {
        value: 0,
        labelEn: "Fail",
        labelKm: "ខូច",
      },
    ],
    points: [
      {
        key: "front_damage",
        value: {},
        labelEn: "Front Damage",
        labelKm: "គ្រោះថ្នាក់ផ្នែកខាងមុខ",
        required: true,
        requiredImage: 2,
        failOptions: [
          {
            value: 'minor',
            labelEn: "Minor",
            labelKm: "ស្រាល",
          },
          {
            value: 'moderate',
            labelEn: "Moderate",
            labelKm: "មធ្យម",
          },
          {
            value: 'major',
            labelEn: "Major",
            labelKm: "ខ្លាំង",
          },
        ],
      },
      {
        key: "front_left_frame_damage",
        value: {},
        labelEn: "Front Left Frame Damage",
        labelKm: "វៀចសាក់ស៊ីខាងមុខឆ្វង",
        required: true,
        requiredImage: 2,
        failOptions: [
          {
            value: 'minor',
            labelEn: "Minor",
            labelKm: "ស្រាល",
          },
          {
            value: 'moderate',
            labelEn: "Moderate",
            labelKm: "មធ្យម",
          },
          {
            value: 'major',
            labelEn: "Major",
            labelKm: "ខ្លាំង",
          },
        ],
      },
      {
        key: "rear_left_frame_damage",
        value: {},
        labelEn: "Rear Left Frame Damage",
        labelKm: "វៀចសាក់ស៊ីក្រោយឆ្វេង",
        required: true,
        requiredImage: 2,
        failOptions: [
          {
            value: 'minor',
            labelEn: "Minor",
            labelKm: "ស្រាល",
          },
          {
            value: 'moderate',
            labelEn: "Moderate",
            labelKm: "មធ្យម",
          },
          {
            value: 'major',
            labelEn: "Major",
            labelKm: "ខ្លាំង",
          },
        ],
      },
      {
        key: "rear_damage",
        value: {},
        labelEn: "Rear Damage",
        labelKm: "គ្រោះថ្នាក់ផ្នែកខាងក្រោយ",
        required: true,
        requiredImage: 2,
        failOptions: [
          {
            value: 'minor',
            labelEn: "Minor",
            labelKm: "ស្រាល",
          },
          {
            value: 'moderate',
            labelEn: "Moderate",
            labelKm: "មធ្យម",
          },
          {
            value: 'major',
            labelEn: "Major",
            labelKm: "ខ្លាំង",
          },
        ],
      },
      {
        key: "rear_right_frame_damage",
        value: {},
        labelEn: "Rear Right Frame Damage",
        labelKm: "វៀចសាកស៊ីផ្នែកខាងក្រោយស្តាំ",
        required: true,
        requiredImage: 2,
        failOptions: [
          {
            value: 'minor',
            labelEn: "Minor",
            labelKm: "ស្រាល",
          },
          {
            value: 'moderate',
            labelEn: "Moderate",
            labelKm: "មធ្យម",
          },
          {
            value: 'major',
            labelEn: "Major",
            labelKm: "ខ្លាំង",
          },
        ],
      },
      {
        key: "front_right_frame_damage",
        value: {},
        labelEn: "Front Right Frame Damage",
        labelKm: "វៀចសាក់ស៊ីផ្នែកខាងមុខស្តាំ",
        required: true,
        requiredImage: 2,
        failOptions: [
          {
            value: 'minor',
            labelEn: "Minor",
            labelKm: "ស្រាល",
          },
          {
            value: 'moderate',
            labelEn: "Moderate",
            labelKm: "មធ្យម",
          },
          {
            value: 'major',
            labelEn: "Major",
            labelKm: "ខ្លាំង",
          },
        ],
      },
      {
        key: "total_lost",
        value: {},
        labelEn: "Total Lost",
        labelKm: "ខូចខាតធ្ងន់ធ្ងរ",
        required: true,
        requiredImage: 2,
        failOptions: [
          {
            value: 'minor',
            labelEn: "Minor",
            labelKm: "ស្រាល",
          },
          {
            value: 'moderate',
            labelEn: "Moderate",
            labelKm: "មធ្យម",
          },
          {
            value: 'major',
            labelEn: "Major",
            labelKm: "ខ្លាំង",
          },
        ],
      },
    ],
  },
  {
    labelEn: "Other History",
    labelKm: "គ្រោះថ្នាក់ផ្សេងទៀត",
    options: [
      {
        value: 1,
        labelEn: "Yes",
        labelKm: "មាន",
      },
      {
        value: 0,
        labelEn: "No",
        labelKm: "គ្មាន",
      },
    ],
    points: [
      {
        key: "flooded_vehicle",
        value: {},
        labelEn: "Flooded Vehicle",
        labelKm: "ឡានលិចទឹក",
        required: true,
        modalOnYes: true,
        requiredImage: 2,
        failOptions: [],
      },
      {
        key: "fire_damaged_vehicle",
        value: {},
        labelEn: "Fire Damaged Vehicle",
        labelKm: "ឡានគ្រោះថ្នាក់ដោយអគ្គិភ័យ",
        required: true,
        modalOnYes: true,
        requiredImage: 2,
        failOptions: [],
      },
      {
        key: "total_repaint_repainted",
        value: {},
        labelEn: "Total Repaint/Repainted",
        labelKm: "ឡានស្ងោរថ្នាំ",
        required: true,
        requiredImage: 2,
        modalOnYes: true,
        failOptions: [],
      },
    ],
  },
  {
    labelEn: "Drivability Condition",
    labelKm: "ការបើកបរ",
    options: [
      {
        value: 1,
        labelEn: "Pass",
        labelKm: "ល្អ",
      },
      {
        value: 0,
        labelEn: "Fail",
        labelKm: "ខូច",
      },
    ],
    points: [
      {
        key: "engine_starting",
        value: {},
        labelEn: "Engine Starting",
        labelKm: "ការដេរបញ្ឆេះម៉ាស៊ីន",
        required: true,
        requiredImage: 2,
        failOptions: [],
      },
      {
        key: "engine_knocking_noise",
        value: {},
        labelEn: "Engine Knocking Noise",
        labelKm: "ម៉ាស៊ីនឆេះមិនស្រួល​ (អុក)",
        required: true,
        requiredImage: 2,
        failOptions: [],
      },
      {
        key: "engine_emits_white_smoke",
        value: {},
        labelEn: "Engine Emits White Smoke",
        labelKm: "ម៉ាស៊ីនធ្លាក់ហ៊ឺយ",
        required: true,
        requiredImage: 2,
        failOptions: [],
      },
    ],
  },
  {
    labelEn: "Transmission",
    labelKm: "ប្រអប់លេខ",
    options: [
      {
        value: 1,
        labelEn: "Pass",
        labelKm: "ល្អ",
      },
      {
        value: 0,
        labelEn: "Fail",
        labelKm: "ខូច",
      },
    ],
    points: [
      {
        key: "transmission_shift",
        value: {},
        labelEn: "Transmission Shift",
        labelKm: "ស្ថានភាពការប្តូរលេខ",
        required: false,
        requiredImage: 2,
        failOptions: [],
      },
      {
        key: "transmission_transaxle_noise",
        value: {},
        labelEn: "Transmission / Transaxle Noise",
        labelKm: "សំលេងរំខានពីប្រអប់លេខ",
        required: false,
        requiredImage: 2,
        failOptions: [],
      },
      {
        key: "drive_axle_transfer_case_noise_4_x_4_only",
        value: {},
        labelEn: "Drive Axle/Transfer Case Noise(4X4 only)",
        labelKm: "សំលេងរំខានពីប៉ុង​/ កាឡេ",
        required: false,
        requiredImage: 2,
        failOptions: [],
      },
      {
        key: "clutch_operation_mt_only",
        value: {},
        labelEn: "Clutch Operation (MT Only)",
        labelKm: "ដំណើរការអាំប្រាយ៉ា",
        required: false,
        requiredImage: 2,
        failOptions: [],
      },
    ],
  },
];

<template>
  <b-skeleton-wrapper :loading="loading">
    <template #loading>
      <div class="inspection-report">
        <div class="d-flex">
          <b-skeleton class="mr-1" type="button"></b-skeleton>
          <b-skeleton class="mr-1" type="button"></b-skeleton>
          <b-skeleton class="mr-1" type="button"></b-skeleton>
        </div>
        <div class="mt-1">
          <b-skeleton-table :columns="4" :rows="12" borderless striped>
          </b-skeleton-table>
        </div>
      </div>
    </template>
    <div>
      <div v-if="isUpdate">
        <update-inspection-report-form
          ref="updateInspectionReportForm"
          :answers="answers"
          @cancel="cancelUpdate"
          @success="successUpdate"
        />
      </div>
      <div v-else>
        <video-player ref="video" />
        <div class="inspection-report" v-if="answers.length">
          <b-table-simple
            responsive
            borderless
            striped
            sticky-header
            class="table-information mb-0"
          >
            <template v-for="(main, i) in inspectionForm">
              <b-thead>
                <b-tr>
                  <b-th width="50">#</b-th>
                  <b-th>{{ trans(main, "label", $i18n.locale) }}</b-th>
                  <b-th
                    class="text-center"
                    v-for="(option, j) in main.options"
                    :key="`option-${i}-${j}`"
                  >
                    {{ trans(option, "label", $i18n.locale) }}
                  </b-th>
                  <!-- <b-th class="text-center">
                    {{ $t("field.pass") }}
                  </b-th>
                  <b-th class="text-center">
                    {{ $t("field.fail") }}
                  </b-th> -->
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                  v-for="(point, j) in main.points"
                  :key="`point-${i}-${j}`"
                  :class="{
                    na: noAnswers.includes(point.value.answer),
                  }"
                >
                  <b-td>
                    {{ j + 1 }}
                  </b-td>
                  <b-td>
                    {{ trans(point, "label", $i18n.locale) }}
                    <div
                      class="fail-options"
                      v-if="
                        point.value.failOptions &&
                        point.value.failOptions.length
                      "
                    >
                      <b-button
                        variant="primary"
                        size="sm"
                        class="mr-1"
                        v-if="
                          point.value.failMedia &&
                          checkIsImage(point.value.failMedia.fileType)
                        "
                        @click="viewImage(point.value.failMedia)"
                      >
                        <feather-icon icon="ImageIcon" />
                      </b-button>
                      <b-button
                        variant="primary"
                        size="sm"
                        class="mr-1"
                        v-if="
                          point.value.failMedia &&
                          !checkIsImage(point.value.failMedia.fileType)
                        "
                        @click="viewVideo(point.value.failMedia)"
                      >
                        <feather-icon icon="VideoIcon" />
                      </b-button>
                      <b-badge
                        class="text-capitalize"
                        variant="light-danger"
                        pill
                        v-for="(failOption, k) in point.value.failOptions"
                        :key="`fail-option-${i}-${j}-${k}`"
                      >
                        {{
                          trans(
                            getFailOptions(failOption, point.failOptions),
                            "label",
                            $i18n.locale
                          )
                        }}
                      </b-badge>
                    </div>
                  </b-td>
                  <b-td
                    class="pass text-center"
                    v-if="!noAnswers.includes(point.value.answer)"
                  >
                    <feather-icon
                      icon="CheckIcon"
                      size="22"
                      v-if="point.value.answer == 1"
                    />
                  </b-td>
                  <b-td
                    class="fail text-center"
                    v-if="!noAnswers.includes(point.value.answer)"
                  >
                    <feather-icon
                      icon="CheckIcon"
                      size="22"
                      v-if="point.value.answer == 0"
                    />
                  </b-td>
                  <b-td
                    colspan="2"
                    v-if="noAnswers.includes(point.value.answer)"
                    class="text-center"
                  >
                    {{ $t("field.na") }}
                  </b-td>
                </b-tr>
              </b-tbody>
            </template>
          </b-table-simple>
        </div>
        <div class="text-center" v-else>
          <b-img
            class="w-50"
            :src="require('@/assets/images/icons/no-data.svg')"
            fluid
          />
        </div>
      </div>
    </div>
  </b-skeleton-wrapper>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTabs,
  BTab,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BImg,
  BSkeletonWrapper,
  BSkeleton,
  BSkeletonTable,
  BBadge,
  BButton,
} from "bootstrap-vue";
import inspectionForm from "@/data/inspectionForm";
import Repository from "@/repositories/RepositoryFactory";
import VideoPlayer from "@/components/VideoPlayer.vue";
import UpdateInspectionReportForm from "./UpdateInspectionReportForm";

const InspectionRepository = Repository.get("inspection");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTabs,
    BTab,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BImg,
    BSkeletonWrapper,
    BSkeleton,
    BSkeletonTable,
    BBadge,
    BButton,

    VideoPlayer,
    UpdateInspectionReportForm,
  },
  props: {
    isUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inspectionForm: [...JSON.parse(JSON.stringify(inspectionForm))],
      loading: false,
      answers: [],
      noAnswers: [2],
      key: 1,
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    save() {
      this.$refs.updateInspectionReportForm.submit();
    },
    cancel() {
      this.$refs.updateInspectionReportForm.cancel();
    },
    show() {
      this.loading = true;
      InspectionRepository.show(this.$route.params.id)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.answers = [...data];
            if (data.length) {
              this.mergeAnswer(data);
            }
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    mergeAnswer(answers) {
      let sourceArray = answers;
      let destinationArray = [...JSON.parse(JSON.stringify(inspectionForm))];
      sourceArray.forEach((sourceItem) => {
        destinationArray.forEach((destinationItem) => {
          const pointIndex = destinationItem.points.findIndex(
            (pointItem) => pointItem.key === sourceItem.key
          );
          if (pointIndex !== -1) {
            destinationItem.points[pointIndex] = {
              ...destinationItem.points[pointIndex],
              value: sourceItem,
            };
          }
        });
      });
      this.inspectionForm = [...JSON.parse(JSON.stringify(destinationArray))];
    },
    countAllPoints(main) {
      return main.subs.reduce((acc, sub) => {
        return acc + sub.points.length;
      }, 0);
    },
    countPassAnswers(points) {
      return points.reduce((accumulator, currentValue) => {
        if (currentValue.value.answer == 1 || currentValue.value.answer == 2) {
          accumulator++;
        }
        return accumulator;
      }, 0);
    },
    countPassAnswersFromSub(subs) {
      return subs.reduce((acc, curr) => {
        const filteredPoints = curr.points.filter(
          (point) => point.value.answer === 1 || point.value.answer === 2
        );
        return acc + filteredPoints.length;
      }, 0);
    },
    getFailOptions(fail, options) {
      return options.find((obj) => obj.value === fail);
    },
    checkIsImage(fileType) {
      return fileType.startsWith("image/");
    },
    viewImage(media) {
      this.$imagePreview({
        initIndex: 0,
        images: [media.fileUrl],
        zIndex: 9999,
        isEnableDownloadImage: false,
        isEnableImagePageIndicator: false,
        isEnableBlurBackground: true,
        onClose: () => {},
      });
    },
    viewVideo(media) {
      this.$refs.video.play(media.fileUrl);
    },
    cancelUpdate() {
      this.$emit("cancel");
      this.mergeAnswer(this.answers);
    },
    successUpdate() {
      this.$emit("success");
      this.show();
    },
  },
  setup() {
    const resource = "vehicle";
    const route = "vehicle";

    return {
      resource,
      route,
    };
  },
};
</script>

<template>
  <b-skeleton-wrapper :loading="loading">
    <template #loading>
      <div class="image-card">
        <b-row class="image-items">
          <b-col
            v-for="item in 24"
            :key="`main-image-${item}`"
            cols="6"
            md="4"
            lg="3"
            xl="2"
            class="image-item"
          >
            <b-card no-body class="mb-0">
              <b-skeleton-img></b-skeleton-img>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </template>
    <div class="image-card">
      <div v-if="isUpdate">
        <update-main-image-form
          ref="updateMainImageForm"
          :data="mainImages"
          @success="successUpdate"
          @cancel="cancelUpdate"
        />
      </div>

      <div v-else>
        <div class="main-image-upload" v-if="mainImages.length">
          <div
            class="mb-2 mr-2 image-item"
            v-for="(item, i) in mainImages"
            :key="`main-image-${i}`"
          >
            <b-card no-body class="mb-0" @click="viewImage(item.media)">
              <div class="text-center cursor-pointer">
                <b-img fluid :src="item.media.fileUrl" />
              </div>
            </b-card>
          </div>
        </div>
        <div class="text-center" v-else>
          <b-img
            class="w-50"
            :src="require('@/assets/images/icons/no-data.svg')"
            fluid
          />
        </div>
      </div>
    </div>
  </b-skeleton-wrapper>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BImg,
  BSkeletonWrapper,
  BSkeletonImg,
  BButton,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import UpdateMainImageForm from "./UpdateMainImageForm";

const InspectionRepository = Repository.get("inspection");

export default {
  props: {
    isUpdate: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BSkeletonWrapper,
    BSkeletonImg,
    BButton,

    UpdateMainImageForm,
  },
  watch: {
    isUpdate(value) {
      if (value) {
        setTimeout(() => {
          this.$refs.updateMainImageForm.initData(this.mainImages);
        }, 200);
      }
    },
  },
  data() {
    return {
      loading: false,
      mainImages: [],
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    save() {
      this.$refs.updateMainImageForm.submit();
    },
    cancel() {
      this.$refs.updateMainImageForm.cancel();
    },
    show() {
      this.loading = true;
      InspectionRepository.mainImage(this.$route.params.id)
        .then((response) => {
          const data = response?.data?.data;
          this.mainImages = [...data];
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    viewImage(media) {
      this.$imagePreview({
        initIndex: 0,
        images: [media.fileUrl],
        zIndex: 9999,
        isEnableDownloadImage: false,
        isEnableImagePageIndicator: false,
        isEnableBlurBackground: true,
        onClose: () => {},
      });
    },
    update() {
      this.isUpdate = true;
      setTimeout(() => {
        this.$refs.updateMainImageForm.initData(this.mainImages);
      }, 200);
    },
    cancelUpdate() {
      this.$emit("cancel");
    },
    successUpdate(data) {
      this.$emit("success");
      this.show();
    },
  },
  setup() {
    const resource = "vehicle";
    const route = "vehicle";

    return {
      resource,
      route,
    };
  },
};
</script>
<template>
  <b-skeleton-wrapper :loading="loading">
    <template #loading>
      <div class="image-card">
        <b-row class="image-items">
          <b-col
            v-for="item in 24"
            :key="`damage-image-${item}`"
            cols="6"
            md="4"
            lg="3"
            xl="2"
            class="image-item"
          >
            <b-card no-body class="mb-0">
              <b-skeleton-img></b-skeleton-img>

              <b-skeleton width="70%" class="mt-1"></b-skeleton>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </template>
    <div class="image-card">
      <video-player ref="video" />

      <b-row v-if="damageImages.length" class="image-items">
        <template v-for="damageImage in damageImages" v-if="damageImage.point">
          <b-col
            :key="`damage-image-${damageImage.key}`"
            cols="6"
            md="4"
            lg="3"
            xl="2"
            class="image-item"
          >
            <b-card no-body class="mb-0" @click="view(damageImage.failMedia)">
              <div class="text-center cursor-pointer">
                <b-img
                  fluid
                  :src="damageImage.failMedia.fileUrl"
                  v-if="damageImage.failMedia.fileType.startsWith('image')"
                />
                <video :src="damageImage.failMedia.fileUrl" v-else />
                <div class="body">
                  {{ trans(damageImage.point, "label", $i18n.locale) }}
                </div>
              </div>
            </b-card>
          </b-col>
        </template>
      </b-row>
      <div class="text-center" v-else>
        <b-img
          class="w-50"
          :src="require('@/assets/images/icons/no-data.svg')"
          fluid
        />
      </div>
    </div>
  </b-skeleton-wrapper>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardText,
  BRow,
  BCol,
  BImg,
  BSkeletonWrapper,
  BSkeleton,
  BSkeletonImg,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import inspectionForm from "@/data/inspectionForm";
import VideoPlayer from "@/components/VideoPlayer.vue";

const InspectionRepository = Repository.get("inspection");

export default {
  components: {
    BCard,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BImg,
    BSkeletonWrapper,
    BSkeleton,
    BSkeletonImg,

    VideoPlayer,
  },
  data() {
    return {
      loading: false,
      damageImages: [],
      inspectionForm: [...inspectionForm],
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    show() {
      this.loading = true;
      InspectionRepository.damageImage(this.$route.params.id)
        .then((response) => {
          const data = response?.data?.data;
          if (data && data.length) {
            this.mergeAnswer(data);
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    mergeAnswer(damageImages) {
      let sourceArray = this.inspectionForm;
      let destinationArray = damageImages;
      destinationArray.forEach((destinationItem) => {
        sourceArray.forEach((sourceItem) => {
          const pointIndex = sourceItem.points.findIndex(
            (pointItem) => pointItem.key === destinationItem.key
          );
          if (pointIndex !== -1) {
            destinationItem.point = {
              ...sourceItem.points[pointIndex],
            };
          }
        });
      });
      this.damageImages = [...destinationArray];
    },
    view(failMedia) {
      if (failMedia.fileType.startsWith("image")) {
        this.viewImage(failMedia);
      } else {
        this.viewVideo(failMedia);
      }
    },
    viewImage(media) {
      this.$imagePreview({
        initIndex: 0,
        images: [media.fileUrl],
        zIndex: 9999,
        isEnableDownloadImage: false,
        isEnableImagePageIndicator: false,
        isEnableBlurBackground: true,
        onClose: () => {},
      });
    },
    viewVideo(media) {
      this.$refs.video.play(media.fileUrl);
    },
  },
};
</script>
